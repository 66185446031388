@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
   width: 100%;
    height: 100vh;
    background: url(./hero-bg.jpg) top center;
    background-size: cover;
  font-family: "Source Sans Pro", sans-serif;
}
.login-page form button {
  background: linear-gradient(170deg, #8224e3, #ed1e79);
}
.login-page .container {
  background-color: #141518;
  color: white;
}
.login-page .container .or {
  background-color: #141518;
  color: white;
}
.login-page input {
  background-color: #090a0f;
  border-color: transparent;
  color: white;
}
.login-page input:focus {
  outline: none !important;
  border-width: 1px;
  border-color: rgba(130, 36, 227, 0.5);
  box-shadow: 0px 10px 40px 0px rgb(130 36 227 / 15%);
}
.social label {
  margin-left: 5px;
}
.login-page .forget-pass {
  margin-right: 60%;
}
.login-page .link {
      background-image: linear-gradient( 70deg,#8224e3,#ed1e79);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}
